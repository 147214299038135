import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import SEO from "../components/seo"
import marked from "marked"

import CurrencyFormatter from "../components/bigcommerce/CurrencyFormatter"
import ProductQtyButton from "../components/bigcommerce/ProductQtyButton"
import AddToCart from "../components/bigcommerce/AddToCart"
import ProductGallery from "../components/product-gallery"
import ProductOptions from "../components/bigcommerce/ProductOptions"

const ShopTemplate = props => {
  const { product, categories, item, products } = props.data
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const [selectedVariant, setSelectedVariant] = useState(null)
  const [quantity, setQuantity] = useState(1)
  const [optionValues, setOptionValues] = useState([])

  useEffect(() => {
    fetch(
      `/.netlify/functions/bigcommerce?endpoint=catalog/products/${
        product.bigcommerce_id
      }?include=images,variants,custom_fields,options,modifiers,videos`
    )
      .then(async res => ({ response: await res.json(), status: res.status }))
      .then(({ status, response }) => {
        if (status === 200) {
          setData(response.data)
          setOptionValues(response.data.variants[0].option_values)
        }
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        console.log(err)
      })
  }, [product.bigcommerce_id])
  useEffect(() => {
    if (optionValues.length) {
      data.variants.forEach(element => {
        element.option_values.forEach(val => {
          if (
            val.id === optionValues[0].id &&
            val.option_id === optionValues[0].option_id
          ) {
            setSelectedVariant(element)
          }
        })
      })
    } else {
      setSelectedVariant(data && data.variants[0])
    }
  }, [optionValues])

  const productCategories = product.categories.map(item => {
    return categories.nodes.find(category => category.bigcommerce_id === item)
  })

  const productRelated = []
  product.related_products.forEach(item => {
    const findProduct = products.edges.find(
      related => related.node.bigcommerce_id === item
    )
    if (findProduct) productRelated.push(findProduct)
  })

  return (
    <Layout>
      <SEO title={(item && item.productName) || ""} />
      <div id="product-page" style={{ paddingTop: 300, paddingBottom: 50 }}>
        <div className="page-content">
          <div className="container">
            <div className="row">
              <div className="col-md-6 mb-4">
                {item && (
                  <div className="text-center">
                    <ProductGallery gallery={item.gallery} />
                  </div>
                )}
              </div>
              <div className="col-md-6 pl-md-5">
                {loading ? (
                  <div className="text-center">
                    <p>Loading...</p>
                  </div>
                ) : selectedVariant ? (
                  <div>
                    <h1 className="text-purple font-weight-normal">
                      {data.name}
                    </h1>
                    <div>
                      <p className="mb-0">
                        <small>
                          {`CATEGORIES: ${productCategories.map(
                            cat => cat.name
                          )}`}
                        </small>
                      </p>
                      <p className="mb-0">
                        <small>{`SKU: ${selectedVariant.sku}`}</small>
                      </p>
                    </div>
                    <h4 className="my-3">
                      <CurrencyFormatter
                        currency={"USD"}
                        amount={selectedVariant.calculated_price}
                      />
                    </h4>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: marked(data.description),
                      }}
                    />
                    {data.options.length > 0 && (
                      <ProductOptions
                        options={data.options}
                        optionValues={optionValues}
                        setOptionValues={setOptionValues}
                      />
                    )}
                    <ProductQtyButton
                      quantity={quantity}
                      setQuantity={setQuantity}
                    />
                    <AddToCart
                      productId={selectedVariant.product_id}
                      variantId={selectedVariant.id}
                      quantity={quantity}
                    />
                  </div>
                ) : (
                  <h1 className="text-center">No product found</h1>
                )}
              </div>
            </div>
            <div className="related-products">
              <div className="intro text-center mb-5">
                <h5 className="text-purple mb-0 pb-0">RELATED PRODUCTS</h5>
                <hr className="mx-auto" />
              </div>

              {product.related_products !== -1 ? (
                <div className="row">
                  {productRelated &&
                    productRelated.map((item, index) => (
                      <div className="col-md-3 mb-4" key={index}>
                        <div className="product-related text-center">
                          <Link
                            to={`/shop/${item.node.custom_url.url.replace(
                              /\//g,
                              ""
                            )}`}
                          >
                            <img
                              className="img-fluid border"
                              src={item.node.images[0].url_zoom}
                              alt={item.node.name}
                            />
                            <p className="text-purple mb-0">{item.node.name}</p>
                            <p className="mb-0 text-dark">
                              <CurrencyFormatter
                                currency={"USD"}
                                amount={item.node.calculated_price}
                              />
                            </p>
                          </Link>
                        </div>
                      </div>
                    ))}
                </div>
              ) : (
                <p className="text-center">No related products found</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ShopTemplate

export const productQuery = graphql`
  query ProductPageTemplateQuery($slug: String!, $id: Int!) {
    product: bigCommerceProducts(bigcommerce_id: { eq: $id }) {
      bigcommerce_id
      name
      custom_url {
        url
      }
      categories
      related_products
    }
    categories: allBigCommerceCategories {
      nodes {
        bigcommerce_id
        name
      }
    }
    products: allBigCommerceProducts {
      edges {
        node {
          id
          name
          bigcommerce_id
          related_products
          calculated_price
          custom_url {
            url
          }
          images {
            url_zoom
          }
        }
      }
    }
    item: sanityProduct(slug: { current: { eq: $slug } }) {
      id
      mainImage {
        asset {
          fluid {
            src
          }
        }
      }
      productName
      gallery {
        alt
        asset {
          fluid {
            src
          }
          _id
          url
          fixed {
            src
          }
        }
      }
    }
  }
`
