import React, { useState } from "react"

const ProductQtyButton = ({ quantity, setQuantity }) => {
    return (
        <div className="mt-3">
            <p className="text-uppercase mb-1">quantity</p>
            <div className="d-flex align-items-center">
                <button className="btn btn-purple-75 text-white font-weight-bold" onClick={() => setQuantity(prev => (prev < 2 ? 1 : prev - 1))}>
                    {"-"}
                </button>
                <h5 className="m-0 px-3">{quantity}</h5>
                <button className="btn btn-purple-75 text-white font-weight-bold" onClick={() => setQuantity(prev => prev + 1)}>
                    {"+"}
                </button>
            </div>
        </div>
    )
}

export default ProductQtyButton