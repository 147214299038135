import React, { useState, useEffect } from "react"
import Swiper from "react-id-swiper"

export default function ProductGallery({ gallery }) {
  const [gallerySwiper, getGallerySwiper] = useState(null)
  const [thumbnailSwiper, getThumbnailSwiper] = useState(null)
  const gallerySwiperParams = {
    getSwiper: getGallerySwiper,
    speed: 1000,
    fadeEffect: {
      crossFade: true,
    },
    slidesPerView: 1,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  }

  const thumbnailSwiperParams = {
    getSwiper: getThumbnailSwiper,
    speed: 1000,
    containerClass: "swiper-container mt-4",
    spaceBetween: 10,
    centeredSlides: false,
    slidesPerView: 5,
    touchRatio: 0.2,
    slideToClickedSlide: true,
    slideThumbActiveClass: "active-gallery-thumb",
  }

  useEffect(() => {
    if (
      gallerySwiper !== null &&
      gallerySwiper.controller &&
      thumbnailSwiper !== null &&
      thumbnailSwiper.controller
    ) {
      gallerySwiper.controller.control = thumbnailSwiper
      thumbnailSwiper.controller.control = gallerySwiper
    }
  }, [gallerySwiper, thumbnailSwiper])

  return (
    <div id="product-gallery">
      <Swiper {...gallerySwiperParams}>
        {gallery.map((productImage, productImageIdx) => (
          <div key={productImageIdx} className="text-center border">
            <img
              alt={productImage.asset.id}
              src={productImage.asset.fixed.src}
              className="img-fluid mt-3 mb-3"
            />
          </div>
        ))}
      </Swiper>
      <Swiper {...thumbnailSwiperParams}>
        {gallery.map((productImage, productImageIdx) => (
          <div key={productImageIdx} className="border">
            <img
              alt={productImage.asset.id}
              src={productImage.asset.fixed.src}
              width="100px"
              className="img-fluid mb-0"
            />
          </div>
        ))}
      </Swiper>
    </div>
  )
}
