import React, { useContext } from "react"
import { CartContext } from "../../context/CartContext"

const AddToCart = ({ productId, variantId, quantity }) => {
  const { addToCart, state } = useContext(CartContext)

  const handleClick = () => {
    addToCart(productId, variantId, quantity)
  }
  return (
    <div className="mt-4">
      <div className="add-cart-options">
        <button
          className="btn"
          onClick={handleClick}
          disabled={state.addingToCart === productId}
        ><i class="fa fa-shopping-cart mr-2" aria-hidden="true"></i>
          {state.addingToCart === productId ? "adding to cart" : "add to cart"}
        </button>

      </div>
    </div>
  )
}

export default AddToCart
