import React from "react"

const ProductOptions = ({ options, optionValues, setOptionValues }) => {
  const handleOption = option => {
    switch (option.type) {
      case "radio_buttons":
        return (
          <RadioButtons
            option={option}
            optionValues={optionValues}
            setOptionValues={setOptionValues}
          />
        )
      default:
        return null
    }
  }

  return (
    <div className="mt-3">
      {options.map(option => (
        <div key={option.id}>{handleOption(option)}</div>
      ))}
    </div>
  )
}

export default ProductOptions

const RadioButtons = ({ option, optionValues, setOptionValues }) => {
  return (
    <div>
      <h5 className="mb-0">{option.display_name}</h5>
      {option.option_values.map(optVal => {
        const isSelected =
          optionValues[0].id === optVal.id &&
          optionValues[0].option_id === option.id

        const myOption = [{ id: optVal.id, option_id: option.id }]

        return (
          <div key={optVal.id}>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                id={optVal.id}
                name={option.id}
                value={optVal.id}
                onChange={() => setOptionValues(myOption)}
                checked={isSelected}
              />
              <label
                className="form-check-label"
                htmlFor={optVal.id}
                style={{ fontSize: 14 }}
              >
                {optVal.label}
              </label>
            </div>
          </div>
        )
      })}
    </div>
  )
}
